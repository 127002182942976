@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';


$reference-width: 1280;
@function vw-calc($size) {
	$vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}

.LandingPage {
	height: 100%;
	width: 100%;
	overflow: hidden;
	background-image: url('../../assets/images/bg-landing.svg');
	background-repeat: no-repeat;
	background-size: 57.1em auto;
	background-position: center 5em;
	@include flex('flex-start', 'center');
	flex-direction: column;
	.LandingPage-logo-wrap {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		position: absolute;
		bottom: 0;
		z-index: 1;
		.LandingPage-cgl-logo {
			width: 8em;
			height: 10em;
			background-image: url('../../assets/images/logo-cgl-dark.svg');
			background-repeat: no-repeat;
			background-size: 7em auto;
			background-position: left center;
			margin-right: 1em;
			
		}
		
		.LandingPage-LF-logo {
			width: 8em;
			height: 3em;
			background-image: url('../../assets/images/LandbrugFodevare.svg');
			background-repeat: no-repeat;
			background-size: auto auto;
			background-position: center center;
		}
	}
	
	.LandingPage-wrap {
		position: relative;
		width: 30em;
		text-align: center;
		padding-top: 1em;
		z-index: 2;
		.LandingPage-title {
			margin: 0.5em 0;
			margin-bottom: 7em;
			text-transform: uppercase;
			font-family: 'AvenirBold';
			letter-spacing: 0.07em;
		}

		.PigImageLogin {
			width: 19.375em;
			margin-bottom: 1em;
			height: auto;
		}
		.LandingPage-startBtn {
			margin: 0 auto;
			width: 10em;
		}
	}
}