@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';


$reference-width: 1280;
@function vw-calc($size) {
	$vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}
.Explore {
	display: inline-block;
	vertical-align: top;
	width: calc(100% - 23.125em);
	position: relative;
	height: 100%;
	font-family: 'AvenirBold';
	text-transform: uppercase;
	.Explore-content {
		width: 100%;
		&--showSubtopics {
			.InfoBox {
				@include flex('space-between', 'flex-end', 'column');
			}
			.Explore-topics {
				display: none;
			}
		}
	}

	.InfoBox-videoIcon.animate,
	.InfoBox-recipeIcon.animate,
	.InfoBox-sustainabilityIcon.animate,
	.InfoBox-imageIcon.animate,
	.InfoBox-cuttingInstructionsIcon.animate {
		circle {@include changeIconBgColor(0.7);}
	}

	&--farm,
	&--pig-transformation,
	&--farmer-life {
		.Explore-topic.animate {
			@include fadeInOut(0.6);
			> div:before {
				display: none;
			}
		}
	}

	.Explore-location.animate {
		background-color: white;
		@include fadeInOut(0.6);
	}

	.Explore-subtopic.animate {
		@include fadeInOut(0.6);
	}

	.Explore-subtopic2.animate {
		@include fadeInOut(0.6);
	}
	
	.InfoBox.animate,
	.FunFactBox.animate {
		@include pulse(0.5);
		animation-iteration-count: 1;
	}
	
	.Explore-topic.animate > div::before {
		@include fadeInOut(0.6);
	}

	.Explore-backBtn {
		position: fixed;
		top: 0.5em;
		right: 31%;
		font-size: 0.9em;
	}

	.Explore-gameTitle {
		font-family: 'AvenirBold';
		position: relative;
		font-size: 1.75em;
		padding-left: 1.75em;
		padding-top: 0.25em;
	}

	.Explore-subtitle {
		font-family: 'AvenirBold';
		margin-top: -1.8em;
		width: 18em;
		padding-left: 3.55em;
		font-size: 0.875em;
		color: $blue-light;
	}

	.InfoPanel {
		position: absolute;
		top: 6em;
		left: 3.125em;
		width: 18.75em;
		height: 30em;
		// overflow: hidden;
		@include flex('space-between', 'center', 'column');
	}

	.InfoBox {
		width: 100%;
		height: 100%;
		background-color: white;
		border-radius: 1em;
		padding: 0.5em;
		padding-left: 1em;
		padding-right: 0.75em;
		margin-bottom: 0.5em;
		display: none;
		z-index: 2;
		text-transform: none;
		font-family: 'Avenir';
		position: relative;
		@include box-shadow(0, 0.1876em, 0.25em, 0, rgba($blue-dark, 0.16));
		&.maxHeight {
			max-height: 100%;
			height: auto;
			overflow: hidden;
			.InfoBox-body.maxHeight {
				overflow: hidden !important;
			}
		}
		&.withFunFact {
			max-height: 75%;
			.InfoBox-body {
				overflow: auto;
			}
		}
		.InfoBox-body {
			text-align: left;
			width: 100%;
			height: 100%;
			
			@include fancy-scrollbar(0.5em, 100%, rgba($blue-dark, 0.2), 0.5em, 100%, 1.5em, $blue-dark);
			&.maxHeight {
				// height: auto;
				overflow: auto;
				// .InfoBox-text {max-height: 27em;}
			}
			.InfoBox-text {
				line-height: 1.5em;
				font-size: 1.075em;
				word-wrap: break-word;
				// max-height: 17em;
				
				p {margin: 0;}
				pre {margin: 0;}
				code {
					font-family: 'Avenir';
					white-space: pre-line;
				}
			}
		}
		.InfoBox-assets {
			width: 100%;
			padding-right: 0.5em;
			margin-bottom: -0.25em;
			height: auto;
			display: flex;
			@include flex('flex-end', 'center');
		}
		.InfoBox-recipeIcon,
		.InfoBox-videoIcon,
		.InfoBox-imageIcon,
		.InfoBox-sustainabilityIcon,
		.InfoBox-cuttingInstructionsIcon {
			width: 2.625em;
			height: 2.625em;
			display: inline-block;
			margin-left: 0.5em;
			margin-top: 0.5em;
			cursor: pointer;
			svg {
				width: 100%;
				height: 100%;
			}
			circle {transition: all 0.2s ease-out;}
			&:hover,
			&.selected {
				circle {fill: $blue-dark;}
			}
		}
	}

	.FunFactBox {
		width: 100%;
		height: auto;
		max-height: 8.2em;
		background-color: white;
		border-radius: 1em;
		z-index: 2;
		text-transform: none;
		font-family: 'Avenir';
		display: block;
		position: relative;
		@include box-shadow(0, 0.1876em, 0.25em, 0, rgba($blue-dark, 0.16));
		.FunFactBox-title {
			font-family: 'AvenirBold';
			padding: 0.5em;
			padding-left: 1em;
			font-size: 0.875em;
			text-transform: uppercase;
			background-color: $blue-dark;
			color: white;
			border-top-left-radius: 1em;
			border-top-right-radius: 1em;
		}
		.FunFactBox-text {
			padding: 0.5em;
			padding-top: 0.5em;
			padding-bottom: 0.5em;
			font-size: 1.075em;
			padding-left: 1em;
			line-height: 1.4em;
			p {
				margin: 0;
			}
		}
	}

	.Explore-topics {
		width: 49.798125em;
		text-align: center;
		height: 37.335625em;
		padding-top: 0.4em;
		margin-left: 7em;
		@include flex('space-between', 'flex-start');
		background-image: url('../../assets/images/bg-explore.svg');
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: center 0.5em;
		.Explore-topic {
			overflow-x: visible;
			width: 33%;
			cursor: pointer;
			&.no-interaction {
				cursor: default;
			}
				
			
			img {
				position: relative;
				z-index: 2;
				width: 100%;
				height: 100%;
			}
			> div {
				position: relative;
				font-size: 0.875em;
				font-family: 'AvenirBold';
				margin-top: -1.7em;
				margin-left: 1em;
				div {
					position: relative;
					font-family: inherit;
				}
				&::before {
					content: '';
					position: absolute;
					width: 18.88em;
					height: 18.8em;
					top: -16.36em;
					left: 1.16em;
					background-size: contain;
					background-repeat: no-repeat;
					background-image: url('../../assets/images/butchery-topic-hover.svg');
					transition: opacity 0.3s ease-out;
					@include opacity(0);
				}
				
			}
			&--happens-at-butchery {
				img {
					margin-left: 0.5em;
					width: 18em;
					height: auto;
				}
				> div {
					width: 18em;
					margin-left: 2.3em;
					&::before {
						top: -16.45em;
						left: 0.03em;
					}
				}
			}
			&--butcher {
				img {
					width: 17.8125em;
					margin-top: 0.1em;
					margin-left: -0.6em;
				}
				> div {
					margin-left: -1.3em;
					&::before {
						top: -16.36em;
						left: 1.16em;
					}
				}
			}
			&--engine-room {
				img {
					width: 17.8em;
					margin-top: 0.15em;
					margin-left: -4.7em;
				}
				> div {
					margin-left: -4.75em;
					&::before {
						left: 2.01em;
					}
				}
			}
			
			&--pig-transformation {
				width: 30em;
			}
			
			&--archetypes,
			&--international,
			&--detail,
			&--food-service {
				&.animate {
					@include fadeInOut(0.6);
					> div:before {
						display: none;
					}
				}
			}
			
		}
	}

	.Explore-subtopics {
		text-align: center;
		position: relative;
		// width: 44em;
		width: 55.334em;
		margin-left: 9em;
		.Explore-topicImg {
			position: absolute;
			top: 8em;
			left: 14em;
			width: 15em;
			&--pig-transformation {
				width: 20em;
				left: 10em;
			}
			&--farmer-life {
				width: 10em;
				left: 17em;
			}
		}
		.Explore-subtopicWrap {
			position: absolute;
			@include flex('flex-start', 'flex-start', 'column');
			z-index: 1;
			font-family: 'Avenir';
			.Explore-subtopic {
				font-size: 0.875em;
				padding: 0.3em 1em;
				display: inline-block;
				margin-right: 1em;
				border-radius: 1em;
				color: white;
				background-color: $blue-sky;
				cursor: pointer;
				transition: all 0.2s ease-out;
				&:hover,
				&.selected {
					background-color: $blue-dark;
				}
			}
			.Explore-subtopic2 {
				font-size: 0.875em;
				padding: 0.3em 1em;
				margin-right: 1em;
				margin-top: 0.5em;
				border-radius: 1em;
				color: $blue-dark;
				background-color: $blue-sky;
				cursor: pointer;
				z-index: 1;
				transition: all 0.2s ease-out;
				&:hover,
				&.selected {
					color: $blue-dark;
					background-color: white;
				}
			}
		}
	}

	.Explore-locations {
		position: fixed;
		// left: 3.125em;
		left: 2.778em;
		// top: 37em;
		top: 37.334em;
		z-index: 10;
		@include flex('flex-start', 'center');
		.Explore-location {
			// width: 9.4em;
			width: 5.8em;
			height: 4em;
			// height: 6.8125em;
			margin-left: 0.1667em;
			text-align: center;
			border-radius: 1em;
			// padding: 0.625em;
		
			cursor: pointer;
			transition: all 0.3s ease-out;
			@include flex('flex-end', 'center', 'column');
			img {
				// width: 8.125em;
				// width: 4.8234em;
				height: auto;
				padding-bottom: 0.0778em;
			}
			div {
				font-family: 'AvenirBold';
				margin-top: 0.4em;
				font-size: 0.5em;
				// font-size: 0.75em;
				padding-bottom: 0.76112em;
			}
			&:nth-child(1) {
				img {width: 4.8234em;}
				
			}
			&:nth-child(2) {
				img {
					width: 4.081em;
					
				}
			}
			&:nth-child(3) {
				img {width: 3.5389em;}
			}
			&:nth-child(4) {
				img {width: 5.07667em;}
			}
			// &:last-of-type {
			// 	// margin-left: 1em;
			// 	img {width: 6.875em;}
			// }
			&:hover,
			&.selected {
				background-color: white;
			}
		}
	}

	&--location {
		&.Explore--butchery {
			.InfoPanel {
				justify-content: flex-end;
				.InfoBox {
					
					display: initial;
					height: 14.5em;
					margin-bottom: 0em;
					.InfoBox-body .InfoBox-text {
						line-height: 1.4em;
						// overflow: hidden;
						height: 9.8em;
						overflow: auto;
						@include fancy-scrollbar(0.5em, 100%, rgba($blue-dark, 0.2), 0.5em, 100%, 1.5em, $blue-dark);
					}
					.InfoBox-assets {
						margin-top: -3em;
					}
				}
			}
			.Explore-topics {
				.Explore-topic {
					&.animate img {
						animation: none;
					}
					&:hover {
						> div::before {
							@include opacity(1);
						}
					}
				}
			}

		}
		&.Explore--farm {
			width: 100%;
			background-image: url('../../assets/images/bg-explore-farm.svg');
			background-size: 66.12375em;
			background-repeat: no-repeat;
			background-position: 5em 3em;
			.Explore-content {
				padding-top: 2.75em;
				margin-left: -6em;
			}
			.Explore-topics {
				display: initial;
				width: 41em;
				height: 26em;
				background: none;
				.Explore-topic {
					display: inline-block;
					position: absolute;
					div {
						margin-top: -0.5em;
					}
					&--farmer-life {
						width: 12.6875em;
						left: 15em;
						top: 7.8em;
					}
					&--pig-transformation {
						width: 30em;
						left: 28em;
						top: 19.5em;
					}
				}
			}
		}
		&.Explore--consumer {
			width: 100%;
			background-image: url('../../assets/images/bg-explore-consumer.svg');
			background-size: 57.79776em;
			background-repeat: no-repeat;
			background-position: 0em 2.1667em;
			.Explore-topics {
				display: initial;
				width: 41em;
				height: 26em;
				background: none;
				.Explore-topic {
					display: inline-block;
					position: absolute;
					
					&--food-service {
						width: 14.34778em;
						left: 1.60556em;
						top: 15.1223em;
						div {
							margin-top: -0.5em;
						}
					}

					&--detail {
						width: 24.218334em;
						left: 16.778em;
						top: 5.9em;
						div {
							margin-top: 0em;
							margin-left: -1em;
						}
					}

					&--international {
						width: 13.790556em;
						left: 40.17223em;
						top: 11.66112em;
						div {
							margin-top: 0em;
							margin-left: -1em;
						}
					}
					&--archetypes {
						width: 15.9em;
						left: 19.38334em;
						top: 22.6em;
						div {
							margin-top: 0em;
						}
					}
				}
			}
		}
		&.Explore--education {
			width: 100%;
			background-image: url('../../assets/images/bg-explore-education.svg');
			background-size: 33.208889em;
			background-repeat: no-repeat;
			background-position: 26.87778em 7.088889em;
			.InfoPanel {
				justify-content: flex-start;
				// overflow: hidden;

				.InfoBox {
					display: initial;
					height: auto;
					margin-bottom: 0.5em;
					.InfoBox-body .InfoBox-text {
						line-height: 1.4em;
						overflow: hidden;
					}
					.InfoBox-assets {
						margin-top: -3em;
					}
				}
				.FunFactBox {
					height: 10.5em;
				}
				
			}
			.Explore-topics {
				.Explore-topic {
					&.animate img {
						animation: none;
					}
					&:hover {
						> div::before {
							@include opacity(1);
						}
					}
				}
			}
			.Explore-topics {
				display: initial;
				width: 41em;
				height: 26em;
				background: none;
				.Explore-topic {
					display: inline-block;
					position: absolute;
				}
			}
		}
	}

	&--topic {
		&.Explore--farmer-life,
		&.Explore--pig-transformation {
			width: 100%;
			background-image: url('../../assets/images/bg-explore-farm.svg');
			background-size: 66.12375em;
			background-repeat: no-repeat;
			background-position: 5em 3em;
			.Explore-content--showSubtopics .Explore-topics {
				background-image: none;
				display: initial;
				.Explore-topic {
					display: inline-block;
					position: absolute;
					transition: all 0.5s ease;
					& > div {display: none;}
				}
			}
			.Explore-subtopics {
				
				position: absolute;
				top: 5.2em;
				left: 9em;
			}
			.Explore-content.Explore-content.Explore-content--subtopic2-5 {
				.InfoBox .InfoBox-body {
					overflow: auto !important;
				}
			}
		}
		&.Explore--farmer-life {
			.InfoPanel {
				@include fadeIn(1);
			}
			.Explore-content--subtopic-2 {
				.InfoBox .InfoBox-body {
					overflow: auto !important;
				}
			}
			
			.Explore-topic--farmer-life {
				width: 15.92em;
				left: 23em;
				top: 4em;
			}
			.Explore-topic--pig-transformation {
				width: 15em;
				left: 45em;
				top: 18em;
				
			}
			.Explore-subtopics {
				.Explore-topicImg {

					display: none;
				}
				.Explore-subtopicWrap {
					&:nth-child(1) {
						top: 2em;
						left: 24.5em;
						width: 15em;
					}
					&:nth-child(2) {
						top: 24em;
						left: 24.5em;
						width: 15em;
					}
				}
			}
		}
		&.Explore--pig-transformation {
			.InfoPanel {
				@include fadeIn(1);
			}
			.InfoBox.withFunFact {
				max-height: 18em;
			}
			.Explore-topic--farmer-life {
				width: 6.75em;
				left: 26em;
				top: 10em;
			}
			.Explore-topic--pig-transformation {
				top: 21em;
				left: 24em;
				width: 34.936875em;
				
			}
			.Explore-content--subtopic-3,
			.Explore-content--subtopic-2 {
				.InfoBox .InfoBox-body {
					overflow: auto !important;
					// background-color: red;
				}
				.InfoBox {
					&.withFunFact {
						max-height: 25em;
					}
				}
				&.Explore-content--subtopic2-1,
				&.Explore-content--subtopic2-2 {
					.InfoBox {
						&.withFunFact {
							max-height: 23em;
						}
					}
				}
				&.Explore-content--subtopic2-4 {
					.InfoBox {
						&.withFunFact {
							max-height: 21em;
						}
					}
				}
			}
			.Explore-content--subtopic-1 {
				.InfoBox {
					&.withFunFact {
						max-height: 21em;
					}
				}
			}
			
			.Explore-subtopics {
				.Explore-topicImg {
					display: none;
				}
				.Explore-subtopicWrap {
					&:nth-child(1) {
						top: 0em;
						left: 10em;
					}
					&:nth-child(2) {
						top: 2em;
						left: 24.5em;
						width: inherit;
					}
					&:nth-child(3) {
						top: 19em;
						left: 10.5em;
						width: inherit;
						z-index: 2;
					}
				}
			}
		}
		&.Explore--happens-at-butchery {
			.Explore-content.Explore-content--subtopic-3 {
				.InfoBox .InfoBox-body {
					overflow: auto;
				}
			}
			.Explore-content--subtopic-2,
			.Explore-content--subtopic-3 {
				.InfoBox.withFunFact {
					max-height: 22em;
				}
			}
			.InfoBox.withFunFact {
				max-height: 20em;
			}
			.Explore-subtopics .Explore-subtopicWrap {
				&:nth-child(1) {
					top: 9em;
					left: 17em;
				}
				&:nth-child(2) {
					top: -1.5em;
					left: 30em;
					width: 18em;
				}
				&:nth-child(3) {
					top: 4.5em;
					left: 37em;
					width: 18em;
				}
			}
		}
		&.Explore--butcher {
			.Explore-content.Explore-content--subtopic-2 {
				.InfoBox .InfoBox-body {
					overflow: auto;
				}
			}
			.Explore-content.Explore-content--subtopic-1 {
				.InfoBox .InfoBox-body {
					overflow: auto;
				}
			}
			.Explore-content.Explore-content--subtopic2-1 {
				.InfoBox .InfoBox-body {
					overflow: auto;
				}
			}
			.Explore-content--subtopic-4 {
				&.Explore-content--subtopic2-1 {
					.InfoBox .InfoBox-body {
						overflow: auto !important;
					}
				}
			}

			.Explore-content--subtopic-1 {
				&.Explore-content--subtopic2-2,
				&.Explore-content--subtopic2-3 {
					.InfoBox.withFunFact {
						max-height: 23em;
					}
				}

			}
			
			.InfoBox.withFunFact {
				max-height: 21em;
			}
			// .FunFactBox .FunFactBox-text {
				// height: 5em;
				// margin-right: 0.5em;
				// margin-top: 0.1em;
				// margin-bottom: 0.1em;
				// // line-height: 1.2em;
				// overflow-y: auto;
				// @include fancy-scrollbar(0.5em, 100%, rgba($blue-dark, 0.2), 0.5em, 100%, 1.5em, $blue-dark);
			// }

			.Explore-subtopics .Explore-subtopicWrap {
				&:nth-child(1) {
					top: 7em;
					left: 23em;
					
				}
				&:nth-child(2) {
					top: 25em;
					left: 25em;
					// width: 25em;
				}
				&:nth-child(3) {
					// top: 16em;
					// left: 44em;
					top: 17em;
					left: 34em;
				}
				&:nth-child(4) {
					top: 18em;
					left: 15em;
				}
			}
		}
		&.Explore--engine-room {
			.Explore-subtopics {
				.Explore-subtopicWrap {
					.Explore-subtopic {width: inherit;}
					&:nth-child(1) {
						top: -1em;
						left: 24.5em;
					}
					&:nth-child(2) {
						top: -0.5em;
						left: 37em;
						width: 11em;
					}
					&:nth-child(3) {
						top: 5.5em;
						left: 44em;
					}
					&:nth-child(4) {
						top: 3em;
						left: 16em;
					}
				}
			}
			
		}
		&.Explore--loin,
		&.Explore--skinke,
		&.Explore--bryst,
		&.Explore--indmad,
		&.Explore--head,
		&.Explore--tenderloin,
		&.Explore--neck,
		&.Explore--tail,
		&.Explore--bagskank,
		&.Explore--bov,
		&.Explore--skank {
			background-image: url('../../assets/images/bg-pig-cuts.svg');
			background-repeat: no-repeat;
			background-size: 54.625em auto;
			background-position: 7em 4.55em;
			.Explore-subtopicWrap .Explore-subtopic::after {
				content: '';
				position: absolute;
				left: 50%;
				top: 2em;
				width: 3.740625em;
				height: 4.946875em;
				background-image: url('../../assets/images/pointer.svg');
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				margin-left: -0.15em;
				@include opacity(0);
			}
			.Explore-subtopicWrap .Explore-subtopic.selected::after {
				@include opacity(1);
			}
		}
		&.Explore--skank {
			.InfoBox.withFunFact {
				overflow: hidden;
			}
		}
		&.Explore--loin {
			.Explore-subtopicWrap {
				&:nth-child(1) {
					top: 4.1em;
					left: 15em;
				}
				&:nth-child(2) {
					top: 5em;
					left: 36em;
					width: 10em;
					.Explore-subtopic::after {
						top: 2.2em;
						left: 1em;
						transform: scaleX(-1) rotate(15deg);
					}
				}
				&:nth-child(3) {
					top: 21.5em;
					left: 39em;
					width: 10em;
					.Explore-subtopic::after {
						top: -5.3em;
						left: 1em;
						transform: scale(-1) rotate(30deg);
					}
				}
				&:nth-child(4) {
					top: 12.2em;
					left: 14em;
					.Explore-subtopic::after {
						top: 2em;
						left: 4em;
						transform: rotate(10deg);
					}
				}
			}
		}
		&.Explore--skinke {
			.Explore-subtopicWrap {
				&:nth-child(1) {
					top: 6em;
					left: 37.5em;
					.Explore-subtopic::after {
						top: 2.15em;
						left: 1em;
						transform: scaleX(-1) rotate(10deg);
					}
				}
				&:nth-child(2) {
					top: 11em;
					left: 16em;
				}
				&:nth-child(3) {
					top: 5em;
					left: 16em;
				}
			}
		}
		&.Explore--bryst {
			.Explore-subtopicWrap {
				&:nth-child(1) {
					top: 13em;
					left: 15em;
				}
				&:nth-child(2) {
					top: 4em;
					left: 37em;
					.Explore-subtopic::after {
						top: 1.5em;
						left: -1em;
						transform: scaleX(-1) rotate(10deg);
					}
				}
				&:nth-child(3) {
					top: 13em;
					left: 39em;
					.Explore-subtopic::after {
						top: 1em;
						left: -2em;
						transform: scaleX(-1) rotate(-10deg);
					}
				}
				&:nth-child(4) {
					top: 18em;
					left: 27em;
					.Explore-subtopic::after {
						top: 1em;
						left: -2em;
						transform: scaleX(-1) rotate(-10deg);
					}
				}
				&:nth-child(5) {
					top: 5em;
					left: 25em;
					.Explore-subtopic::after {
						top: 1em;
						left: -2em;
						transform: scaleX(-1) rotate(-10deg);
					}
				}
			}
		}
		&.Explore--indmad {
			.Explore-subtopicWrap {
				&:nth-child(1) {
					top: 11.5em;
					left: 15em;
					.Explore-subtopic::after {
						transform: rotate(0deg);
					}
				}
				&:nth-child(2) {
					top: 10em;
					left: 38em;
					.Explore-subtopic::after {
						left: -1em;
						transform: scaleX(-1) rotate(5deg);
					}
				}
				&:nth-child(3) {
					top: 17em;
					left: 25.3em;
					.Explore-subtopic::after {
						left: 1em;
						transform: scaleX(-1) rotate(35deg);
					}
				}
			}
		}
		&.Explore--neck {
			.Explore-subtopicWrap {
				&:nth-child(1) {
					top: 15em;
					left: 34em;
					.Explore-subtopic::after {
						transform: rotate(90deg);
						top: 1.2em;
						left: -3em;
						
					}
				}
				&:nth-child(2) {
					top: 6em;
					left: 32em;
					.Explore-subtopic::after {
						left: -2em;
						transform: scaleX(-1) rotate(5deg);
					}
				}
			}
		}
		&.Explore--head {
			.Explore-subtopicWrap {
				&:nth-child(1) {
					top: 10em;
					left: 18.5em;
					.Explore-subtopic::after {
						transform: rotate(0deg);
						left:8em;
					}
				}
			}
		}
		&.Explore--bov {
			.Explore-subtopicWrap {
				&:nth-child(1) {
					top: 6em;
					left: 34.5em;
					.Explore-subtopic::after {
						top: 2.15em;
						left: 1em;
						transform: scaleX(-1) rotate(10deg);
					}
				}
				&:nth-child(2) {
					top: 11em;
					left: 16em;
				}
				// &:nth-child(3) {
				// 	top: 5em;
				// 	left: 16em;
				// }
			}
		}
		
		&.Explore--international {
			.Explore-topicImg {
				width: 49.798125em;
				top: 1em;
				left: 9em;
				content: url('../../assets/images/img-international-selected.svg');
			}
			.Explore-subtopicWrap {
				&:nth-child(1) {
					top: 8.5em;
					left: 18em;
					.Explore-subtopic::after {
						transform: rotate(0deg);
					}
				}
				&:nth-child(2) {
					top: 6.8em;
					left: 33em;
					width: 15em;
				}
				&:nth-child(3) {
					// top: 16em;
					// left: 44em;
					top: 16em;
					left: 30em;
				}
				&:nth-child(4) {
					top: 14em;
					left: 37em;
				}
			}
		}
		&.Explore--detail {
			.Explore-content--subtopic-2.Explore-content--subtopic2-2 {
				.InfoBox .InfoBox-body {
					overflow: auto;
				}
			}
			.Explore-content--subtopic-4.Explore-content--subtopic2-1 {
				.InfoBox .InfoBox-body {
					overflow: auto !important;
				}
			}

			
			.Explore-topicImg {
				width: 49.798125em;
				top: 1em;
				left: 9em;
				content: url('../../assets/images/img-detail-selected.svg');
			}
			.Explore-subtopicWrap {
				&:nth-child(1) {
					top: 1.8em;
					left: 27em;
					.Explore-subtopic::after {
						transform: rotate(0deg);
					}
				}
				&:nth-child(2) {
					top: 18.5em;
					left: 14em;
					width: 15em;
				}
				&:nth-child(3) {
					// top: 16em;
					// left: 44em;
					top: 8em;
					left: 43.5em;
				}
				&:nth-child(4) {
					top: 1.8em;
					left: 13.5em;
				}
			}
		}
		&.Explore--food-service {
			.Explore-topicImg {
				width: 49.798125em;
				top: 1em;
				left: 9em;
				content: url('../../assets/images/img-food-service-selected.svg');
			}
			.Explore-subtopicWrap {
				&:nth-child(1) {
					top: 3.5em;
					left: 37.5em;
					.Explore-subtopic::after {
						transform: rotate(0deg);
					}
				}
			}
		}

		&.Explore--archetypes {
			.Explore-topicImg {
				width: 57.797778em;
				top: 1em;
				left: 9em;
				content: url('../../assets/images/img-archetypes-selected.svg'); //todo: change img
			}
			.Explore-subtopicWrap {
				&:nth-child(1) {
					// top: 11.5em;
					top: calc(34.83334em - 4em);
					left: calc(39.3334em - 6em);
					.Explore-subtopic::after {
						transform: rotate(0deg);
					}
				}
				&:nth-child(2) {
					top: calc(31.83334em - 4em);
					left: calc(22.3334em - 6em);
					width: 15em;
				}
				&:nth-child(3) {
					// top: 16em;
					// left: 44em;
					top: calc(31.83334em - 4em);
					left: calc(46.3334em - 7em);
				}
				&:nth-child(4) {
					top: calc(34.83334em - 4em);
					left: calc(29.3334em - 5em);
				}
			}
		}
		
	}


	&--topic:not(.Explore--grisen) {
		.Explore-subtitle {
			text-transform: uppercase;
			width: 100%;
		}
	}
	&--topic.Explore--grisen {
		.InfoBox {
			display: initial;
		}
	}
}
.Explore--farm,
.Explore--pig-transformation {
	.InfoBox.animate,
	.FunFactBox.animate {
		@include pulse(1.5);
		animation-iteration-count: 1;
	}
}