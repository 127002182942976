/* BODY TEXT */
@font-face {
	font-family: 'Avenir';
	src: url('../assets/fonts/AvenirMedium.woff2');
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: 'AvenirBold';
	src: url('../assets/fonts/AvenirLTStd-Black.woff2');
	font-style: normal;
}