@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';


$reference-width: 1280;
@function vw-calc($size) {
	$vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}
.Loading {
	width: 100%;
	height: 100%;
	position: relative;
	text-align: center;
	padding: 3.5em 2.4em;
	padding-top: 8em;
	.Loading-content {
		.Loading-circles {
			width: 100%;
			@include flex('center', 'center');
			.Loading-circle {
				width: 2em;
				height: 2em;
				margin: 0 0.5em;
				background-color: $blue-dark;
				border-radius: 2em;
			}
		}
		.Loading-text {
			margin-top: 1em;
		}
	}
	.LogoutBtn {
		position: absolute;
		top: 0.5em;
		left: 0.08em;
		width: 5em;
		height: 2em;
		font-size: 1em;
		cursor: pointer;
		z-index: 100;
	}
}

@for $i from 1 to 4 {
	.Loading-circle:nth-child(#{$i}) {
		animation: loading 600ms ease #{300 * $i}ms alternate infinite;
	}
}
@keyframes loading {
	to {
		background-color: rgba($blue-dark, 0.1);
	}
}

@media only screen and (max-aspect-ratio: 1/1) {
	.Loading .Loading-panel {
		width: 100%;
	}
}