@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';


$reference-width: 1280;
@function vw-calc($size) {
	$vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}
.Quiz {
	height: 100%;
	&--paused {.Quiz-answer {cursor: not-allowed;}}
}

.Quiz-header {
	// position: absolute;
	// top: 0em;
	// left: 0em;
	padding: 1em 0;
	margin-bottom: 1em;
	text-align: left;
	z-index: 1;
}

.Quiz-body {
	position: relative;
	// padding-top: calc(1 * 16 * ((100vw / 360)));
	margin: 0em 0 1.5em 0;
	z-index: 0;
}


.Quiz-question {
	position: relative;
	font-size: 1.25em;
	line-height: 1.2;
	color: $white;
	padding: 0;
	p {display: inline; margin: 0;}
}
.Quiz-question {
	&--left {
		text-align: left;
		display: inline-block;
		margin-right: 2em;
		width: 50%;
		// padding: 0.25em 1.25em 0.5em 1.25em;
	}
}



.Quiz-imageThumb {
	display: inline-block;
	position: relative;
	text-align: center;
	
	img {
		vertical-align: middle;
		height: 5em;
		margin-top: -1em;
	}
	.Quiz-imageThumbZoom {
		display: inline-block;
		vertical-align: middle;
		margin-left: 0.5em;
		width: 2.5em;
		height: 2.5em;
		background-color: red;
		// background-image: url('../../../assets/images/icon-zoom.svg');
		background-size: contain;
		background-position: center right;
		background-repeat: no-repeat;
	}
}

.Quiz-instructions {
	text-align: left;
	font-weight: bold;
}

.Quiz-clues {
	padding-top: 1em;
	font-size: 1.25em;
	text-align: left;
	color: $white;
}

.Quiz-clueInfo {
	text-align: left;
	margin-bottom: 0.5em;
	text-transform: uppercase;
	span {
		font-weight: bold;
		// color: $pink;
		text-transform: uppercase;
	}
}
.Quiz-clueText {
	position: relative;
	text-align: left;
	padding-left: 1em;
	padding-bottom: 0.125em;
	p {display: inline; margin: 0;}
}
.Quiz-getClueBtn {
	margin-top: 0.5em;
	font-weight: bold;
	color: $white;
	text-transform: uppercase;
	span {
		// color: $pink;
		text-decoration: underline;
	}
}

.Quiz-image {
	width: 100%;
	height: 15em;
	background-size: 100% auto;
	background-position: bottom center;
	background-repeat: no-repeat;
}

.Quiz-answers {
	padding-top: 1em 1em 0 1em;
}

.Quiz-image + .Quiz-answers,
.Quiz-clues + .Quiz-answers {
	padding-top: 0;
}

.Quiz-answer {
	@include flex('flex-start', 'center');
	line-height: 1.2;
	padding: 0.5em 1em;
	overflow: hidden;
	text-align: left;
	color: $white;
	border-radius: 0.75em;
	background-color: rgba($blue-sky, 0.5);
	margin-bottom: 1em;
	font-size: 1.25em;
	cursor: pointer;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);	
	p {display: inline; margin: 0;}
	&.animateCorrect {
		color: $white;
		background-image: linear-gradient(#78BA59, #128F33);;
		// background-image: url('../../../assets/images/box-answer-4-correct.svg');
		-webkit-animation: bounce 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
		-webkit-animation-fill-mode: forwards;
		animation: bounce 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
		animation-fill-mode: forwards;
	}
	&.animateWrong {
		color: $white;
		background-image: linear-gradient(#D1091F, #82202F);
		// background-image: url('../../../assets/images/box-answer-4-wrong.svg');
		-webkit-animation: wobble 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
		-webkit-animation-fill-mode: forwards;
		animation: wobble 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
		animation-fill-mode: forwards;
	}
	&.correct {
		background-image: linear-gradient(#78BA59, #128F33);
		color: $white;
		// background-image: url('../../../assets/images/box-answer-4-correct.svg');
	}
	&.wrong {
		color: $white;
		background-image: linear-gradient(#D1091F, #82202F);
		// background-image: url('../../../assets/images/box-answer-4-wrong.svg');
	}
}