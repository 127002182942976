@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';


$reference-width: 1280;
@function vw-calc($size) {
	$vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}
.ThemeProgress {
	position: sticky;
	@include flex('flex-start', 'flex-start', 'row');
	.ThemeProgress-step {
		display: inline-block;
		width: 1em;
		min-width: 1em;
		height: 1em;
		border-radius: 1em;
		background-color: #638294;
		margin: 1em 0 1em 0;
		margin-right: 0.4em;
		position: relative;
		border: 0.25em solid transparent;
		&::after {
			content: "";
			width: 0.5em;
			height: 0.17em;
			background-color: #638294;
			position: absolute;
			top: 0.18em;
			right: -0.7em;
		}
		&:last-of-type::after {
			display: none;
		}
		&--selected {
			border: 0.25em solid $white;
			&::after {
				z-index: -1;
			}
		}
		&--completed {
			background-image: linear-gradient(#78BA59, #128F33);
			border: 0.25em solid $white;
			&::after {
				z-index: -1;
				background-color: $white;
			}
		}
		&.ThemeProgress-step--selected.ThemeProgress-step--completed {
			&::after {
				background-color: $white;
			}
		}
	}
}