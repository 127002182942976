@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';


$reference-width: 1280;
@function vw-calc($size) {
	$vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}
.Pig {
	display: none;
	position: absolute;
	width: 34.936875em;
	height: 18.136875em;
	top: 22em;
	left: 23.4em;
	right: 0;
	margin: 0;
	max-width: 100%;
	background-image: url('../../assets/images/pig/pig.svg');
	background-repeat: no-repeat;
	background-size: contain;
	transition: all 0.3s ease-in-out;
	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		
	}
	&.animate {
		path {
			pointer-events: none !important;
		}
		.Pig-cutWrap.selected .Pig-cut:not(.Pig-cut--zoomed) {
			@include fadeInOut(0.6);
			animation-delay: 1s;
		}
		background-image: none;
		&::before {
			background-image: url('../../assets/images/pig/pig.svg');
			background-repeat: no-repeat;
			background-size: contain;
			@include fadeInOut(0.6);
			animation-delay: 1s;
		}
	}
	&.animateOnce.Pig--showCuts{
		@include pulse(0.5);
		animation-iteration-count: 1;
	}
	&--showCuts {
		display: initial;	
	}
	&.freeze {
		pointer-events: none !important;
		path {
			pointer-events: none !important;
		}
		.Pig-cutWrap {
			&:hover {
				path {@include opacity(0);}
				.Pig-cut--zoomed {
					@include opacity(0);
					visibility: visible;
				}
			}
		}
	}
	.Pig-cuts {
		&::after {
			content: 'Udskæringer';
			width: 100%;
			font-family: 'AvenirBold';
			position: absolute;
			bottom: 3em;
			left: -1.5em;
			right: 0;
			text-align: center;
			font-size: 0.875em;
			
		}
	}
	.Pig-cutWrap {
		pointer-events: none;
		&:hover {
			path {@include opacity(1); cursor: pointer;}
			.Pig-cut--zoomed {
				@include opacity(1);
				visibility: visible;
			}
		}
		&.animate {
			@include opacity(0);
			path {@include opacity(1);}
			.Pig-cut--zoomed {
				@include opacity(1);
				visibility: visible;
			}
			@include fadeInOut(0.7);
			animation-delay: 0.5s;
		}
	}
	.Pig-cut {
		position: absolute;
		&--zoomed {
			position: fixed;
			visibility: hidden;
			@include opacity(0);
			transition: all 0.3s ease-out;
			@include doubleTransition('opacity', 0.2s, 'ease-out', 'visibility', 0.2s, 'ease-out');
		}
		svg {
			pointer-events: none;
			width: 100%;
			height: auto;
			z-index: 1;
		}
		path {
			pointer-events: auto;
			z-index: 2;
			transition: all 0.3s ease-out;
			@include opacity(0);
		}
		&--loin {
			width: 8.425em;
			height: 4.381875em;
			top: 0.03em;
			left: 12.15em;
			&.Pig-cut--zoomed {
				width: 9.93em;
				height: 6.635em;
				top: 19.8em;
				left: 34em;
			}
			
		}
		&--skinke {
			width: 7.954375em;
			height: 9.915625em;
			top: 0.25em;
			left: 3.3em;
			&.Pig-cut--zoomed {
				top: -0.38em;
				left: 2.5em;
				top: 21.5em;
				left: 25.9em;
			}
		}
		&--bryst {
			width: 9.1075em;
			height: 5.21875em;
			top: 6.54em;
			left: 12.05em;
			&.Pig-cut--zoomed {
				top: 28.8em;
				left: 34.5em;
				width: 9.6525em;
				height: 7.10875em;
			}
		}
		&--indmad {
			width: 7.165em;
			height: 2.40875em;
			top: 4.28em;
			left: 19.4em;
			&.Pig-cut--zoomed {
				top: 24.5em;
				left: 43em;
				width: 7.066875em;
				height: 4.595em;
			}
		}
		&--skank {
			width: 4.82em;
			height: 6.644375em;
			top: 7.93em;
			left: 20.28em;
			&.Pig-cut--zoomed {
				top: 29.5em;
				left: 43.2em;
				width: 4.83625em;
				height: 6.86125em;
			}
		}
		&--neck {
			width: 6.705em;
			height: 2.50875em;
			top: 0.28em;
			left: 19.55em;
			&.Pig-cut--zoomed {
				top: 20.8em;
				left: 42.5em;
				width: 7.566875em;
				height: 4.995em;
			}
		}
		&--head {
			width: 9.53778em;
			height: 8.53556em;
			top: 0.2em;
			left: 25.58em;
			&.Pig-cut--zoomed {
				top: 22.1em;
				left: 49.2em;
				width: 9.73778em;
				height: 9.8556em;
			}
		}
		&--tenderloin {
			width: 7.565em;
			height: 2.50875em;
			top: 4.28em;
			left: 12.05em;
			&.Pig-cut--zoomed {
				top: 25.5em;
				left: 35.5em;
				width: 7.566875em;
				height: 4.995em;
			}
		}
		&--tail {
			width: 4.2em;
			height: 4em;
			top: 0.35em;
			left: -0.2em;
			
			&.Pig-cut--zoomed {
				top: 22em;
				left: 23.2em;
				width: 4.2em;
				height: 4em;
			}
		}
		&--bagskank {
			width: 4.8em;
			height: 4em;
			top: 9.5em;
			left: 5.3em;
			
			&.Pig-cut--zoomed {
				top: 30.6em;
				left: 28.4em;
				width: 4.7em;
				height: 4em;
			}
		}
		&--bov {
			width: 4.8em;
			height: 4em;
			top: 7.25em;
			left: 23.6em;
			
			&.Pig-cut--zoomed {
				top: 30em;
				left: 46.2em;
				width: 5.5em;
				height: 4em;
			}
		}

		
		
	}

	&--showSubcuts {
		display: initial;
		top: 49em;
		left: 43em;
		font-size: 0.65em;
		cursor: pointer;
		.Pig-cutWrap.animate {
			animation: none;
			&.selected {
				@include opacity(1);
				visibility: visible;
			}
		}
		.Pig-cuts {
			&::after {content: "";
			}
		}
		.Pig-cutWrap {
			pointer-events: none;
			cursor: default;
			&:hover {
				path {@include opacity(0);}
				.Pig-cut--zoomed {
					@include opacity(0);
					visibility: hidden;
					cursor: default;
				}
			}
			&.selected {
				path {
					cursor: default;
					pointer-events: none;
					@include opacity(1);
				}
				.Pig-cut.Pig-cut--zoomed {
					transition: all 0.3s ease-in-out;
					@include opacity(1);
					visibility: visible;
					cursor: default;
					&.Pig-cut--loin {
						font-size: 1.4em;
						top: 10.5em;
						left: 25.7em;
						width: 32.5em;
						height: 21.715625em;
						transform: rotate(12deg);
					}
					&.Pig-cut--skinke {
						top: 19em;
						left: 55em;
						width: 32.5em;
						height: 21.715625em;
						transform: rotate(86deg);
					}
					&.Pig-cut--bryst {
						font-size: 1.4em;
						top: 11.5em;
						left: 27.9em;
						width: 26.25em;
						height: 19.0875em;
						transform: rotate(-9deg);
					}
					&.Pig-cut--indmad {
						font-size: 1.5em;
						top: 15em;
						left: 28.9em;
						width: 20.566875em;
						height: 13.643125em;
					}
					&.Pig-cut--skank {
						font-size: 1.4em;
						top: 10em;
						left: 32em;
						width: 16.859375em;
						height: 22.11875em;
						transform: rotate(45deg);
					}
					&.Pig-cut--head {
						font-size: 1.5em;
						top: 10.9778em;
						left: 35.20556em;
						width: 15.07556em;
						height: 14.866112em;
						transform: rotate(14deg);
					}
					&.Pig-cut--tenderloin {
						font-size: 1.5em;
						top: 20.2112em;
						left: 31.50556em;
						width: 17.28em;
						height: 6.827223em;
						transform: rotate(16deg);
					}
					&.Pig-cut--neck {
						font-size: 1.5em;
						top: 13.9334em;
						left: 25.49445em;
						width: 16.909445em;
						height: 13.208334em;
					}
					&.Pig-cut--tail {
						top: 24.55em;
						left: 42.5em;
						width: 20.063334em;
						height: 9.553334em;
					}
					&.Pig-cut--bagskank {
						top: 26.55em;
						left: 55.5em;
						width: 20.063334em;
						height: 9.553334em;
						transform: rotate(50deg);
					}
					&.Pig-cut--bov {
						top: 23.55em;
						left: 49.5em;
						width: 20.063334em;
						height: 9.553334em;
						transform: rotate(-31deg);
					}
				}
			}
			&.unSelected {
				path {
					cursor: default;
					pointer-events: none;
					@include opacity(1);
				}
				.Pig-cut.Pig-cut--zoomed {
					transition: all 0.3s ease-in-out;
					@include opacity(1);
					visibility: visible;
					cursor: default;
					&.Pig-cut--loin {
						width: 6.93em;
						height: 3.635em;
						top: 33.8em;
						left: 39em;
						transform: rotate(0deg);
					}
					&.Pig-cut--skinke {
						top: 45em;
						left: 47em;
						width: 10.5em;
						height: 5.715625em;
						transform: rotate(0deg);
					}
					&.Pig-cut--bryst {
						top: 39.5em;
						left: 38.9em;
						width: 7.25em;
						height: 6.0875em;
						transform: rotate(0deg);
					}
					&.Pig-cut--indmad {
						top: 34em;
						left: 40.9em;
						width: 5.566875em;
						height: 4.643125em;
					}
					&.Pig-cut--skank {
						top: 40em;
						left: 45em;
						width: 4.059375em;
						height: 8.11875em;
						transform: rotate(0deg);
					}
					&.Pig-cut--tenderloin {
						top: 35em;
						left: 35.9em;
						width: 5.566875em;
						height: 4.643125em;
						transform: rotate(0deg);
					}
					&.Pig-cut--head {
						top: 33em;
						left: 45.9em;
						width: 6.066875em;
						height: 5.643125em;
						transform: rotate(0deg);
					}
					&.Pig-cut--neck {
						top: 32em;
						left: 41.9em;
						width: 5.066875em;
						height: 4.643125em;
						transform: rotate(0deg);
					}
					&.Pig-cut--tail {
						top: 49em;
						left: 42.9em;
						width: 4.2em;
						height: 4em;
					}
					&.Pig-cut--bagskank {
						top: 58em;
						left: 48.5em;
						width: 4.2em;
						height: 4em;
						transform: rotate(0deg);
					}
					&.Pig-cut--bov {
						top: 56.5em;
						left: 66.5em;
						width: 4.7em;
						height: 4em;
						transform: rotate(0deg);
					}
				}
			}
			
		}
		
	}
	
}