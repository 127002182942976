@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';


$reference-width: 1280;
@function vw-calc($size) {
	$vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}
.Popup {
	visibility: hidden;
	pointer-events: none;
	position: fixed;
	top: 4.75em;
	right: 35%;
	width: 20em;
	height: 40em;
	color: $blue-dark;
	background-color: white;
	border-radius: 1em;
	z-index: 20;
	visibility: hidden;
	@include opacity(0);
	@include doubleTransition('opacity', 0.33s, 'ease-in-out', 'visibility', 0.33s, 'ease-in-out');
	@include box-shadow(0, 0.25em, 0.25em, 0, rgba($blue-dark, 0.16));
	font-family: 'Avenir';
	text-transform: none;
	&--show {
		visibility: visible;
		@include opacity(1);
		pointer-events: all;
		&.animate {
			@include pulse(0.5);
			animation-iteration-count: 1;
		}
	}
}

.Popup-body {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 1em;
	padding-top: 1.5em;
	text-align: left;
	color: $blue-dark;
	background-color: white;
	border-radius: 1em;
}

.Popup-close {
	font-size: 0.8em;
	position: absolute;
	top: 0.5em;
	right: 0.5em;
	width: 1.5em;
	height: 1.5em;
	cursor: pointer;
	z-index: 1;
	svg {
		width: 100%;
		height: 100%;
	}
	circle {transition: all 0.2s ease-in-out;}
	&:hover {
		circle {fill: $blue-dark;}
	}
}

.Popup-title {
	font-size: 1.5em;
	font-weight: 700;
	text-transform: uppercase;
	padding: 0 1em 1em 1em;
}

.Popup-text {
	font-size: 1em;
	line-height: 1.2;
	padding-bottom: 2em;
	h1,h2,h3 {
		font-size: 1em;
	}
	p {
		font-size: 1em;
	}
}

.Popup-image {
	width: 100%;
	img {
		width: 100%;
	}
}

.Popup-buttons {
	text-align: center;
	padding: 0 1em;
	@include flex(space-between, center);
}

.Popup-button {
	display: inline-block;
	font-size: 2em;
	font-weight: 700;
	text-transform: uppercase;
	color: white;
	background-color: $text-dark;
	padding: 0.25em 1em;
	border-radius: 0.5em;
}

/* Image */
.Popup.Popup--image {
	width: auto;
	left: 22.5em;
	top: 6em;
	height: auto;
	padding-top: 0.5em;
	// &.Popup--vaskeplads,
	// &.Popup--sort-slagtegang,
	// &.Popup--ren-slagtegang,
	// &.Popup--tarmrenser-kontrol {
	// 	width: 37em;
	// }

	// &.Popup--expedition {
	// 	width: 23em;
	// }
	&.Popup--vaskeplads-gamle-dage {
		width: 29.5em;
	}

	
	&.Popup--slagter-påklædning-sikkerhedsudstyr {
		padding-top: 0.5em;
		width: 21.2em;
	}
	&.Popup--uddannelses-forløb {
		width: 29.8em;
	}
	&.Popup--tema-hovedforløb {
		.Popup-body {
			padding-right: 0.3em;
		}
		.Popup-image {
			margin-right: 0.5em;
			max-height: 34em;
			overflow: auto;
			@include fancy-scrollbar(0.5em, 100%, rgba($blue-dark, 0.2), 0.5em, 100%, 1.5em, $blue-dark);
		}
	}
	&.Popup--Grisens-udvikling-2,
	&.Popup--Grisens-udvikling,
	&.Popup--Griseracer,
	&.Popup--Skoleophold_tarmrenser,
	&.Popup--Jaka,
	&.Popup--Tarmset-inkl-mave,
	&.Popup--Grisehale {
		width: 19em;
	}
	&.Popup--vaskeplads-gamle-dage {
		width: 30em;
	}
	&.Popup--Slagtere,
	&.Popup--Grisestalde,
	&.Popup--Lille-Gris,
	&.Popup--Udskæringsplanche,
	&.Popup--flæskesteg-hel,
	&.Popup--saute-skiver-gris,
	&.Popup--fillet-royal,
	&.Popup--svinekam-uden-svær,
	&.Popup--skank-gris,
	&.Popup--stegeflæsk,
	&.Popup--bryst_hel_uden_ben_overside,
	&.Popup--svinebryst,
	&.Popup--ribbenssteg_af_stegestykke_med_ben,
	&.Popup--rullesteg_af_slag,
	&.Popup--slag,
	&.Popup--lever-gris,
	&.Popup--hjerte-gris,
	&.Popup--minutstrimler,
	&.Popup--mignon,
	&.Popup--hakket_8_procent_fedt,
	&.Popup--tenderloin,
	&.Popup--nakkefilet,
	&.Popup--nakkesteg,
	&.Popup--kaebe,
	&.Popup--bagskank,
	&.Popup--bov_steg,
	&.Popup--eksport-gris,
	&.Popup--udskæring-gamle-dage
	 {
		width: 35em;
	}
	
}

/* Video */
.Popup.Popup--video {
	width: 33em;
	left: 22.5em;
	top: 6em;
	height: 21em;
}

/* Recipe */
.Popup.Popup--recipe {
	width: 23em;
	height: 23em;
	left: 22.5em;
	top: 6em;
	.Popup-body {
		padding-right: 0.25em;
		padding-bottom: 0.25em;
		display: none;
	}
	.Popup-close {
		top: 0.2em;
		right: 0.2em;
		// right: 1em;
	}
	.Popup-text {
		height: 100%;
		overflow: auto;
		padding-right: 1em;
		word-wrap: break-word;
		@include fancy-scrollbar(0.5em, 100%, rgba($blue-dark, 0.2), 0.5em, 100%, 1.5em, $blue-dark);
		p {font-size: 1em;}
	}
	li {font-size: 1em;}
	a {color: $blue-dark;}
	&.Popup--show {
		.Popup-body {
			display: block;
		}
	}
}

/* Cutting Instructions */
.Popup.Popup--cuttingInstructions {
	width: 19.2em;
	left: 22.5em;
	top: 6em;
	height: auto;
	max-height: 25em;
	overflow: auto;
	@include fancy-scrollbar(0.5em, 100%, rgba($blue-dark, 0.2), 0.5em, 100%, 1.5em, $blue-dark);
	.Popup-text {
		padding: 0;
		font-size: 1em;
		p {margin: 0;}
		pre {margin: 0;}
		code {
			font-family: 'Avenir';
			white-space: pre-line;
		}
	}
}

/* sustainability */
.Popup.Popup--sustainability {
	width: 19.2em;
	left: 22.5em;
	top: 6em;
	height: auto;
	max-height: 25em;
	overflow: auto;
	@include fancy-scrollbar(0.5em, 100%, rgba($blue-dark, 0.2), 0.5em, 100%, 1.5em, $blue-dark);
	.Popup-text {
		padding: 0;
		font-size: 1em;
		p {margin: 0;}
		pre {margin: 0;}
		code {
			font-family: 'Avenir';
			white-space: pre-line;
		}
	}
}

