@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';


$reference-width: 1280;
@function vw-calc($size) {
	$vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}
.Pairs {
	height: 100%;
	&--paused {.Pairs-answer {cursor: not-allowed;}}
}

.Pairs-header {
	width: 100%;
	text-align: left;
	z-index: 1;
}

.Pairs-question {
	position: relative;
	font-size: 1.25em;
	line-height: 1.2;
	text-align: left;
	color: $white;
	p {display: inline; margin: 0;}
}

.Pairs-body {
	position: relative;
	padding-top: 2em;
	z-index: 0;
	@include flex('flex-start', 'flex-start');
}

.Pairs-column {
	display: inline-block;
	vertical-align: top;
	width: 10.5em;
	height: 100%;
	text-align: left;
	&--a {
		padding-right: 1em;
		border-right: 2px solid rgba($blue-sky, 0.5);
	}
	&--b {
		padding-left: 1em;
	}
	@include hide-scrollbar();
}

.Pairs-card {
	@include flex('center', 'center');
	height: 4em;
	line-height: 1.2;
	font-size: 1.2em;
	padding: 0.5em;
	text-align: center;
	color: $white;
	border-radius: 0.75em;
	margin-bottom: 1em;
	overflow: hidden;
	background-color: rgba($blue-sky, 0.5);
	// background-image: url('../../../assets/images/box-answer-5.svg');
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	cursor: pointer;
	p {display: inline; margin: 0}
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	&.bgImg {
		background-image: none;
		img {
			width: 100%;
			border: 3px solid transparent;}
			border-radius: 0.5em;
	}	
	&.selected {
		background-color: rgba($blue-sky, 1);
		// background-image: url('../../../assets/images/box-answer-5-selected.svg');
		&.bgImg {
			background-image: none;
		}
	}
	&.correct {
		color: $white;
		background-image: linear-gradient(#78BA59, #128F33);
		// background-image: url('../../../assets/images/box-answer-5-correct.svg');
		-webkit-animation: bounce 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
		-webkit-animation-fill-mode: forwards;
		animation: bounce 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
		animation-fill-mode: forwards;
		&.bgImg {
			background-image: none;
		}
	}
	&.wrong {
		color: $white;
		background-image: linear-gradient(#D1091F, #82202F);
		// background-image: url('../../../assets/images/box-answer-5-wrong.svg');
		-webkit-animation: wobble 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
		animation: wobble 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
		&.bgImg {
			background-image: none;
		}
	}
}