@import '../styles/variables';
@import '../styles/fonts';
@import '../styles/mixins';
@import '../styles/animations';


$reference-width: 1280;
@function vw-calc($size) {
	$vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}
.App {
	height: 100%;
	min-height: 100%;
	@include flex($justify:"center", $align:"center", $direction:"");
	.App-wrap {
		width: 100%;
		height: 100%;
		@include flex($justify:"center", $align:"center", $direction:"");
	}
	overflow-y: hidden;
}

@media (min-aspect-ratio: 16/9) {
	.App .App-wrap {
		font-size: calc(100vh / 45.25);
	}
}
