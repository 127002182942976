@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';


$reference-width: 1280;
@function vw-calc($size) {
	$vw-size: $size / $reference-width * 100;
	$rem-size: $vw-size / 100;
	@return #{$rem-size}rem;
}
.ThemeOverview {
	display: inline-block;
	width: 100%;
	background-color: $blue-dark;
	color: $white;
	height: 100%;
	vertical-align: top;
	position: fixed;
	left: 62.25em;
	z-index: 100;
}

.ThemeOverview-wrap {
	width: 23.125em;
	padding-top: 2em;
	padding-bottom: 3em;
	padding-left: 2.5em;
	padding-right: 2.5em;
	height: 100%;
}

.ThemeOverview-title {
	margin-top: 1.7em;
	margin-bottom: 0.5em;
	font-size: 1.75em;
	text-transform: uppercase;
	position: relative;
	font-family: 'AvenirBold';
	&::before {
		content: '';
		width: 1.5em;
		height: 1.5em;
		position: absolute;
		background-image: url('../../assets/images/icon-tail-filled.svg');
		background-size: 85%;
		background-repeat: no-repeat;
		background-position: 0.08em 0.2em;
		border: 0.15em solid #3F7B92;
		border-radius: 50%;
		top: -2em;
		left: 1.2em;
	}
}

.ThemeOverview-themes {
	position: relative;
	max-width: 100%;
	margin-top: 1em;
	text-align: left;
	
	.ThemeOverview-theme {
		background-color: rgba($blue-sky, 0.5);
		text-transform: uppercase;
		font-family: 'AvenirBold';
		margin: 1em 0;
		border-radius: 0.5em;
		cursor: pointer;
		font-size: 1em;
		padding: 0.5em 1em;
		padding-top: 0.6em;
		padding-right: 3.5em;
		position: relative;
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 0.5em;
			width: 3.049375em;
			height: 3.049375em;
			background-size: contain;
			background-repeat: no-repeat;
		}

		&--bronze::after {background-image: url('../../assets/images/icon-medal-bronze.svg');}
		&--silver::after {background-image: url('../../assets/images/icon-medal-silver.svg');}
		&--gold::after {background-image: url('../../assets/images/icon-medal-gold.svg');}
	}
}
